@import "../../common/colours.scss";

.payment-successful-screen-modal {
    flex: 1;
    background-color: white;
    margin-right: 20px;
    left: 20px;
    right: 10px;
    position: absolute;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 30%;
    margin-bottom: 30%;
}

.payment-result-details-box {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}

.payment-result-details-row {
    display: flex;
    margin-top: 12px;
}

.payment-result-details-lable {
    color: $light-grey-text;
    text-align: left;
}

.payment-result-details-value-text {
    color: $light-grey-text;
    flex: 1;
    text-align: right;
    font-weight: 700;
}

.payment-result-disclaimer {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $light-grey-text;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
}