@import "./common/colours.scss";

a {
  text-decoration: none;
}

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background-grey;
}

.add-shading {
  -webkit-box-shadow: 0px 0px 5px 4px $shadow-orange;
  -moz-box-shadow: 0px 0px 5px 4px $shadow-orange;
  box-shadow: 0px 4px 7px 2px $shadow-orange;
}

.heading-text {
  font-size: 26px;
  color: $base-grey;
}

.bold-centered-heading-text {
  font-size: 26px;
  color: $grey-text;
  font-weight: 700;
  text-align: center;
}

.flex-row-box {
  display: flex;
}

.push-up {
  margin-top: 30px;
}

.push-down {
  margin-bottom: 30px;
}

.link-button-text {
  color: $grey-text;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-top: 40px;
  text-decoration: none;
}

.input-field-parent-box {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: column;
  margin-bottom: 16px;
  flex: 1;
}

.input-field-lable {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  color: $grey-text;
}

.input-field-box {
  border: 1px solid $border-grey;
  border-radius: 10px;
  height: 40px;
  display: flex;
  margin-top: 8px;
}

.input-field {
  flex: 1;
  border-radius: 10px;
  border: 0;
  width: 100%;
}

.background-screen-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(185, 185, 185, 0.5);
  backdrop-filter: blur(3px);
  z-index: 10;
  display: flex;
}

.result-icon-box {
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
}

.close-icon-box {
  align-self: flex-end;
  justify-content: flex-end;
  margin: 10px;
}

.loader {
  border: 16px solid $base-orange;
  border-top: 16px solid $light-grey-text;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 300ms linear infinite;
  align-self: center;
  margin-top: 20px;
}

.button-loader {
  border: 5px solid $base-orange;
  border-top: 5px solid $light-grey-text;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 300ms linear infinite;
  align-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
