@import "../../common/colours.scss";

.wide-button {
  flex: 1;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 500;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 18px;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 6px;
  border: 0;
  display: flex;
  cursor: pointer;
}

.regular-button-style {
  background-color: $base-orange;
  color: white;
}

.alternate-button-style {
  background-color: white;
  color: $button-grey;
  border: 1px solid $shadow-orange;
}
