$base-grey: #bababa;
$shadow-grey: #c7c7c7;
$light-grey-text: #8e8e93;
$grey-text: #414141;
$background-grey: #f7f7f7;
$control-grey: #e3e3e3;
$border-grey: #d1d2d8;
$button-grey: #9a9a9a;
$link-button-grey: #c4c4c4;
$card-background-grey: #a8a8a8;
$base-orange: #ea5a12;
$shadow-orange: rgba(234, 90, 18, 0.3);
$base-navy: #1b1c3c;
