@import "../../common/colours.scss";

.add-card-screen-modal {
  flex: 1;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 40%;
  margin-right: 20px;
  left: 20px;
  right: 10px;
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.add-card-screen-checkbox {
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.wpwl-form {
  background: #f8f8f8;
}
.wpwl-label-brand {
  display: none;
}
.wpwl-form-card {
  background: #f8f8f8;
  border: 0px;
}
.wpwl-control,
input.wpwl-control {
  background: white;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0px;
  font-family: "ff-good-headline-web-pro-con", "Pathway Gothic One", sans-serif;
  text-transform: none;
  padding: 10px;
  font-size: 18px;
  height: 50px;
}
.input-text:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
  background-color: white;
}
.wpwl-brand-SOFORTUEBERWEISUNG,
.wpwl-brand-GIROPAY {
  cursor: pointer;
}
.wpwl-brand,
.wpwl-img {
  margin: 0 0 0 auto;
}

.add-payment-form {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
