@import "../../common/colours.scss";

.card-payment-option {
  margin-top: 20px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.card-type-logo-box {
  align-self: center;
  margin-left: 20px;
  min-width: 60px;
}

.card-type-logo {
  height: 20px;
}

.card-type-smaller-logo {
  height: 30px;
  object-fit: cover;
}

.card-number-text {
  align-self: center;
  margin-left: 20px;
  color: $grey-text;
}

.cvv-number-box {
  align-self: center;
  border-radius: 10px;
  border: 1px $border-grey solid;
  width: 100px;
  height: 40px;
  background-color: white;
}

.cvv-number-field {
  color: #8e8e93;
  font-weight: 600;
  font-size: 16px;
  border: 0;
  border-radius: 10px;
  height: 38px;
  width: 96px;
}

.delete-card-box {
  margin: 20px;
}

.delete-icon {
  align-self: center;
  cursor: pointer;
}
