@import "../../common/colours.scss";

.delete-card-screen-modal {
    flex: 1;
    background-color: white;
    margin-top: 40%;
    margin-bottom: 40%;
    margin-right: 20px;
    left: 20px;
    right: 10px;
    position: absolute;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.delete-card-text {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    padding-bottom: 0px;
    color: $light-grey-text;
    font-size: 14px;
    font-weight: 500;
}

.delete-card-buttons-box {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.delete-card-button {
    flex: 1;
}