@import "../../common/colours.scss";

.content-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
}

.validation-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.validating-payment-heading {
  text-align: center;
  font-size: 24px;
  color: $light-grey-text;
}

.payment-successful {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
}
