@import "../../common/colours.scss";

.virtual-card-box {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: $base-navy;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.virtual-card-top-box {
  display: flex;
  justify-content: space-between;
}

.virtual-card-type-box {
  display: flex;
  margin-top: 20px;
}

.virtual-card-type-logo {
  margin-right: 5px;
}

.virtual-card-type-text {
  font-size: 10px;
  margin-top: 1px;
}

.virtual-card-yoyo-logo-box {
  justify-self: flex-end;
  margin-top: 20px;
}

.virtual-card-card-number-heading {
  font-size: 16px;
  margin-top: 30px;
}

.virtual-card-card-number-text {
  margin-top: 10px;
}

.virtual-card-lower-details-box {
  margin-top: 40px;
  margin-bottom: 20px;
}

.virtual-card-lower-text-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.virtual-card-lower-text {
  font-size: 16px;
}
